<template>
    <div class="reportEatServeTarget defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="inline-block select" style="width: 164px;">
                    <el-select v-model="showType" default-first-option>
                        <el-option :value="1" label="按菜品大类"></el-option>
                        <el-option :value="2" label="按菜品小类"></el-option>
                        <el-option :value="3" label="按一级出品部门"></el-option>
                        <el-option :value="4" label="按二级出品部门"></el-option>
                        <el-option :value="5" label="按设置指标"></el-option>
                    </el-select>
                </div>
                <div class="bnt verticalCenter selected" >
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block search-flex">
                    <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix" v-if="showType==1">
            <div class="table-box grey-table isFooter" ref="tableBox" >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column label="序号" width="50" type="index">
                        <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                    </el-table-column>
                    <el-table-column prop="Kind_Name" label="大类名称" min-width="150"></el-table-column>
                    <el-table-column prop="Verify_TotalNum" label="考核总道数" min-width="100"></el-table-column>
                    <el-table-column prop="OverTime_Num" label="超时菜品数" min-width="100">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.OverTime_Num!=0">{{scope.row.OverTime_Num}}</span>
                            <span v-else>{{scope.row.OverTime_Num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverTime_KindPercent" label="类别超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="OverTime_TotalPercent" label="总超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        <div class="content-box clearfix" v-else-if="showType==2">
            <div class="table-box grey-table isFooter" ref="tableBox" >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                show-summary
                :summary-method="getSummaries"
                 border 
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column label="序号" width="50" type="index">
                        <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                    </el-table-column>
                    <el-table-column prop="Kind_Name" label="小类名称" min-width="150"></el-table-column>
                    <el-table-column prop="Verify_TotalNum" label="考核总道数" min-width="100"></el-table-column>
                    <el-table-column prop="OverTime_Num" label="超时菜品数" min-width="100">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.OverTime_Num!=0">{{scope.row.OverTime_Num}}</span>
                            <span v-else>{{scope.row.OverTime_Num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverTime_KindPercent" label="类别超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="OverTime_TotalPercent" label="总超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        <div class="content-box clearfix" v-else-if="showType==3">
            <div class="table-box grey-table isFooter" ref="tableBox" >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column label="序号" width="50" type="index">
                        <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                    </el-table-column>
                    <el-table-column prop="Kind_Name" label="一级出品部门名称" min-width="150"></el-table-column>
                    <el-table-column prop="Verify_TotalNum" label="考核总道数" min-width="100"></el-table-column>
                    <el-table-column prop="OverTime_Num" label="超时菜品数" min-width="100">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.OverTime_Num!=0">{{scope.row.OverTime_Num}}</span>
                            <span v-else>{{scope.row.OverTime_Num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverTime_KindPercent" label="类别超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="OverTime_TotalPercent" label="总超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        <div class="content-box clearfix" v-else-if="showType==4">
            <div class="table-box grey-table isFooter" ref="tableBox" >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column label="序号" width="50" type="index">
                        <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                    </el-table-column>
                    <el-table-column prop="Kind_Name" label="二级出品部门名称" min-width="150"></el-table-column>
                    <el-table-column prop="Verify_TotalNum" label="考核总道数" min-width="100"></el-table-column>
                    <el-table-column prop="OverTime_Num" label="超时菜品数" min-width="100">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.OverTime_Num!=0">{{scope.row.OverTime_Num}}</span>
                            <span v-else>{{scope.row.OverTime_Num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverTime_KindPercent" label="类别超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="OverTime_TotalPercent" label="总超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        <div class="content-box clearfix" v-else-if="showType==5">
            <div class="table-box grey-table isFooter" ref="tableBox" >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column label="序号" width="50" type="index">
                        <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                    </el-table-column>
                    <el-table-column prop="Kind_Name" label="指标名称" min-width="100"></el-table-column>
                    <el-table-column prop="OverTime_Num" label="超时菜品数" min-width="100">
                        <template #default="scope">
                            <span class="link" v-if="scope.row.OverTime_Num!=0">{{scope.row.OverTime_Num}}</span>
                            <span v-else>{{scope.row.OverTime_Num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverTime_KindPercent" label="超时占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Target_Percent" label="指标占比%" min-width="120" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>

     </div>
</template>
<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

/**上菜顺序速度指标分析表B1 */
export default {
    name:'reportEatServeTarget',
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            //1-按菜品大类 2-按菜品小类 3-按一级出品部门 4-按二级出品部门  5-按设置指标
            showType:1
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        Rpt_Date.setDate(Rpt_Date.getDate()-1);
        this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        /**合计数据 */
        summaryTableList(){
            let total={
                OverTime_Num:0,
                Verify_TotalNum:0,
            };//合计
            if(this.showType==5){
                total.OverTime_KindPercent=0;
            }
            this.tableList?.forEach((it)=>{
                total.OverTime_Num+=it.OverTime_Num;
                total.Verify_TotalNum+=it.Verify_TotalNum;
                if(this.showType==5){
                    total.OverTime_KindPercent+=it.OverTime_KindPercent;
                }
            });
            
            return total;
        },
    },
    watch:{
        showType(){
            this.tableList=[];
        }
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-day;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            let _pagesize = this.pagesize
            this.pagesize = this.tableList.length;//表格长度变长
            this.currentPage= 1;
            this.$refs.tableBox.style.display="none";
            this.$nextTick(function () {
                let table = this.$refs.tableBox.cloneNode(true);
                table.querySelector(".el-table__fixed")?.remove();
                table.querySelectorAll(".el-table__footer td")?.forEach(it=>{
                    it.setAttribute("rowspan","1");
                })
                let xlsxParam = { raw: true };
                let wb = XLSX.utils.table_to_book(table,xlsxParam);
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                try {
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '上菜顺序速度指标分析表.xlsx')
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                this.pagesize = _pagesize;//表格还原
                this.$nextTick(()=>{
                    this.$refs.tableBox.style.display='';
                })
                return wbout
            })
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Analysis_Type:this.showType// 分析类型  1-按菜品大类 2-按菜品小类 3-按一级出品部门 4-按二级出品部门  5-按设置指标
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodServingOrderKpiAnalysisReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="OverTime_Num" && row.OverTime_Num!=0){//穿透 超时菜品明细表
                let KeyWord=this.showType==5? row.Kind_Name:row.Kind_Code;
                let time = JSON.parse(JSON.stringify(this.businessHours));
                this.$emit("addFrame",{
                    key:"overTimeFood"+this.showType+KeyWord,
                    data:{
                        Analysis_Type:this.showType,// 分析类型  1-按菜品大类 2-按菜品小类 3-按一级出品部门 4-按二级出品部门  5-按设置指标
                        KeyWord:KeyWord,
                        Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                        End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                    },
                    title:'超时菜品明细表',
                    url:'reportEatOverTimeFood'
                });
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(column.property=="Verify_TotalNum" || column.property=="OverTime_Num" || (this.showType==5 && column.property=="OverTime_KindPercent")){

                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[1]="合计";
            return sums;
        }
    }
}
</script>

<style lang="scss" >
@import './reportEatServeTarget.scss'
</style>